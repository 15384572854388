<template>
    <a-button-group>
        <a-button 
            type="primary" 
            icon="plus" 
            size="large" 
            @click="addTaskDrawer()">
            {{ addButton && addButton.label ? addButton.label : $t('task.add_task') }}
        </a-button>
        <a-dropdown 
            v-if="toExcel" 
            :trigger="['click']">
            <a-button 
                type="primary"
                icon="fi-rr-menu-dots-vertical"
                flaticon
                size="large" />
            <a-menu slot="overlay">
                <a-menu-item key="0" @click="getFile()">
                    Скачать в Excel
                </a-menu-item>
            </a-menu>
        </a-dropdown>
    </a-button-group>
</template>

<script>
const updateKey = 'file_loading'
export default {
    props: {
        buttonType: {
            type: String,
            default: 'default'
        },
        extendDrawer: {
            type: Boolean,
            default: false
        },
        windowWidth: {
            type: Number,
            required: true
        },
        formParams: {
            type: Object,
            default: () => {}
        },
        showFastTaskAction: {
            type: Boolean,
            default: true
        },
        addButton: {
            type: Object,
            default: () => null
        },
        toExcel: {
            type: Boolean,
            default: false
        },
        page_name: {
            type: String,
            default: ''
        },
        queryParams: {
            type: Object,
            default: () => null
        },
        requestData: {
            type: Object,
            default: () => null
        },
        orderQuery: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            defoultURL: '/tasks/analytics/file/'
        }
    },
    computed: {
        taskType() {
            return this.addButton?.task_type || 'task'
        }
    },
    methods: {
        getEndpointURL() {
            const urlList = {
                'page_list_task_task.TaskModel': '/tasks/task_list/file/',
                'page_list_interest_task.TaskModel': '/tasks/interest_list/file/',
            }
            return urlList[this.page_name] || this.defoultURL
            if(this.page_name === 'page_list_task_task.TaskModel')
                return '/tasks/task_list/file/'

            return this.defoultURL
        },
        async getFile() {
            try {
                this.$message.loading({ content: 'Загрузка', key: updateKey })
                let params = {
                    page_name: this.page_name,
                    task_type: this.taskType
                }

                if(this.queryParams) {
                    params = {
                        ...params,
                        ...this.queryParams
                    }
                }
                if(this.orderQuery)
                    params['ordering'] = this.orderQuery

                const endpointURL = this.getEndpointURL()
                const { data } = await this.$http(endpointURL, {
                    responseType: 'blob',
                    params
                })
                if(data) {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${this.requestData?.name ? `${this.requestData.name}` : ''} статистика от ${this.$moment().format('DD-MM-YYYY HH:mm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                    this.$message.success({ content: 'Файл сформирован', key: updateKey, duration: 0.5 })
                }
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
                this.$message.error({ content: 'Ошибка загрузки', key: updateKey, duration: 2 })
            }
        },
        addTaskDrawer() {
            if(this.extendDrawer)
                this.$store.commit('task/SET_TASK_DRAWER_ZINDEX', 1010)
            this.$store.commit('task/SET_PAGE_NAME', {
                pageName: this.page_name
            })
            this.$store.dispatch('task/sidebarOpen', {
                ...this.formParams,
                task_type: this.taskType
            })
        },
        addTask() {
            this.$store.dispatch('task/editDrawer', true)
        }
    }
}
</script>